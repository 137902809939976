<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'PointLogList',
              }"
              >點數新增紀錄</b-breadcrumb-item
            >
            <b-breadcrumb-item active>點數異動紀錄</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <PointLog />
    </b-card>
  </div>
</template>

<script>
import PointLog from "@/components/Dashboard/PointLog/PointLog.vue"

export default {
  components: {
    PointLog
  },
};
</script>
